article, aside, details, figcaption, figure, footer, header, hgroup, main, nav, section, summary {
  display: block; }

audio, canvas, video {
  display: inline-block;
  *display: inline;
  *zoom: 1; }

/**
 * Prevent modern browsers from displaying `audio` without controls.
 * Remove excess height in iOS 5 devices.
 */
audio:not([controls]) {
  display: none;
  height: 0; }

/**
 * Address styling not present in IE 7/8/9, Firefox 3, and Safari 4.
 * Known issue: no IE 6 support.
 */
[hidden] {
  display: none; }

/* ==========================================================================
   Base
   ========================================================================== */
/**
 * 1. Prevent system color scheme's background color being used in Firefox, IE,
 *    and Opera.
 * 2. Prevent system color scheme's text color being used in Firefox, IE, and
 *    Opera.
 * 3. Correct text resizing oddly in IE 6/7 when body `font-size` is set using
 *    `em` units.
 * 4. Prevent iOS text size adjust after orientation change, without disabling
 *    user zoom.
 */
html {
  background: #fff;
  /* 1 */
  color: #000;
  /* 2 */
  font-size: 100%;
  /* 3 */
  -webkit-text-size-adjust: 100%;
  /* 4 */
  -ms-text-size-adjust: 100%;
  /* 4 */ }

/**
 * Address `font-family` inconsistency between `textarea` and other form
 * elements.
 */
html, button, input, select, textarea {
  font-family: sans-serif; }

/**
 * Address margins handled incorrectly in IE 6/7.
 */
body {
  margin: 0; }

/* ==========================================================================
   Links
   ========================================================================== */
/**
 * Address `outline` inconsistency between Chrome and other browsers.
 */
a:focus {
  outline: thin dotted; }

/**
 * Improve readability when focused and also mouse hovered in all browsers.
 */
a:active, a:hover {
  outline: 0; }

/* ==========================================================================
   Typography
   ========================================================================== */
/**
 * Address font sizes and margins set differently in IE 6/7.
 * Address font sizes within `section` and `article` in Firefox 4+, Safari 5,
 * and Chrome.
 */
h1 {
  font-size: 2em;
  margin: 0.67em 0; }

h2 {
  font-size: 1.5em;
  margin: 0.83em 0; }

h3 {
  font-size: 1.17em;
  margin: 1em 0; }

h4 {
  font-size: 1em;
  margin: 1.33em 0; }

h5 {
  font-size: 0.83em;
  margin: 1.67em 0; }

h6 {
  font-size: 0.67em;
  margin: 2.33em 0; }

/**
 * Address styling not present in IE 7/8/9, Safari 5, and Chrome.
 */
abbr[title] {
  border-bottom: 1px dotted; }

/**
 * Address style set to `bolder` in Firefox 3+, Safari 4/5, and Chrome.
 */
b, strong {
  font-weight: bold; }

blockquote {
  margin: 1em 40px; }

/**
 * Address styling not present in Safari 5 and Chrome.
 */
dfn {
  font-style: italic; }

/**
 * Address differences between Firefox and other browsers.
 * Known issue: no IE 6/7 normalization.
 */
hr {
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  height: 0; }

/**
 * Address styling not present in IE 6/7/8/9.
 */
mark {
  background: #ff0;
  color: #000; }

/**
 * Address margins set differently in IE 6/7.
 */
p, pre {
  margin: 1em 0; }

/**
 * Correct font family set oddly in IE 6, Safari 4/5, and Chrome.
 */
code, kbd, pre, samp {
  font-family: monospace, serif;
  _font-family: 'courier new', monospace;
  font-size: 1em; }

/**
 * Improve readability of pre-formatted text in all browsers.
 */
pre {
  white-space: pre;
  white-space: pre-wrap;
  word-wrap: break-word; }

/**
 * Address CSS quotes not supported in IE 6/7.
 */
q {
  quotes: none; }

/**
 * Address `quotes` property not supported in Safari 4.
 */
q:before, q:after {
  content: '';
  content: none; }

/**
 * Address inconsistent and variable font size in all browsers.
 */
small {
  font-size: 80%; }

/**
 * Prevent `sub` and `sup` affecting `line-height` in all browsers.
 */
sub, sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

/* ==========================================================================
   Lists
   ========================================================================== */
/**
 * Address margins set differently in IE 6/7.
 */
dl, menu, ol, ul {
  margin: 1em 0; }

dd {
  margin: 0 0 0 40px; }

/**
 * Address paddings set differently in IE 6/7.
 */
menu, ol, ul {
  padding: 0 0 0 40px; }

/**
 * Correct list images handled incorrectly in IE 7.
 */
nav ul, nav ol {
  list-style: none;
  list-style-image: none; }

/* ==========================================================================
   Embedded content
   ========================================================================== */
/**
 * 1. Remove border when inside `a` element in IE 6/7/8/9 and Firefox 3.
 * 2. Improve image quality when scaled in IE 7.
 */
img {
  border: 0;
  /* 1 */
  -ms-interpolation-mode: bicubic;
  /* 2 */ }

/**
 * Correct overflow displayed oddly in IE 9.
 */
svg:not(:root) {
  overflow: hidden; }

/* ==========================================================================
   Figures
   ========================================================================== */
/**
 * Address margin not present in IE 6/7/8/9, Safari 5, and Opera 11.
 */
figure {
  margin: 0; }

/* ==========================================================================
   Forms
   ========================================================================== */
/**
 * Correct margin displayed oddly in IE 6/7.
 */
form {
  margin: 0; }

/**
 * Define consistent border, margin, and padding.
 */
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

/**
 * 1. Correct color not being inherited in IE 6/7/8/9.
 * 2. Correct text not wrapping in Firefox 3.
 * 3. Correct alignment displayed oddly in IE 6/7.
 */
legend {
  border: 0;
  /* 1 */
  padding: 0;
  white-space: normal;
  /* 2 */
  *margin-left: -7px;
  /* 3 */ }

/**
 * 1. Correct font size not being inherited in all browsers.
 * 2. Address margins set differently in IE 6/7, Firefox 3+, Safari 5,
 *    and Chrome.
 * 3. Improve appearance and consistency in all browsers.
 */
button, input, select, textarea {
  font-size: 100%;
  /* 1 */
  margin: 0;
  /* 2 */
  vertical-align: baseline;
  /* 3 */
  *vertical-align: middle;
  /* 3 */ }

/**
 * Address Firefox 3+ setting `line-height` on `input` using `!important` in
 * the UA stylesheet.
 */
button, input {
  line-height: normal; }

/**
 * Address inconsistent `text-transform` inheritance for `button` and `select`.
 * All other form control elements do not inherit `text-transform` values.
 * Correct `button` style inheritance in Chrome, Safari 5+, and IE 6+.
 * Correct `select` style inheritance in Firefox 4+ and Opera.
 */
button, select {
  text-transform: none; }

/**
 * 1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native `audio`
 *    and `video` controls.
 * 2. Correct inability to style clickable `input` types in iOS.
 * 3. Improve usability and consistency of cursor style between image-type
 *    `input` and others.
 * 4. Remove inner spacing in IE 7 without affecting normal text inputs.
 *    Known issue: inner spacing remains in IE 6.
 */
button, html input[type="button"], input[type="reset"], input[type="submit"] {
  -webkit-appearance: button;
  /* 2 */
  cursor: pointer;
  /* 3 */
  *overflow: visible;
  /* 4 */ }

/**
 * Re-set default cursor for disabled elements.
 */
button[disabled], html input[disabled] {
  cursor: default; }

/**
 * 1. Address box sizing set to content-box in IE 8/9.
 * 2. Remove excess padding in IE 8/9.
 * 3. Remove excess padding in IE 7.
 *    Known issue: excess padding remains in IE 6.
 */
input[type="checkbox"], input[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */
  *height: 13px;
  /* 3 */
  *width: 13px;
  /* 3 */ }

/**
 * 1. Address `appearance` set to `searchfield` in Safari 5 and Chrome.
 * 2. Address `box-sizing` set to `border-box` in Safari 5 and Chrome
 *    (include `-moz` to future-proof).
 */
input[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  /* 2 */
  box-sizing: content-box; }

/**
 * Remove inner padding and search cancel button in Safari 5 and Chrome
 * on OS X.
 */
input[type="search"]::-webkit-search-cancel-button, input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

/**
 * Remove inner padding and border in Firefox 3+.
 */
button::-moz-focus-inner, input::-moz-focus-inner {
  border: 0;
  padding: 0; }

/**
 * 1. Remove default vertical scrollbar in IE 6/7/8/9.
 * 2. Improve readability and alignment in all browsers.
 */
textarea {
  overflow: auto;
  /* 1 */
  vertical-align: top;
  /* 2 */ }

/* ==========================================================================
   Tables
   ========================================================================== */
/**
 * Remove most spacing between table cells.
 */
table {
  border-collapse: collapse;
  border-spacing: 0; }

body {
  color: #999999; }

body {
  font-family: "futura-pt",sans-serif;
  font-weight: 400;
  font-size: 87.5%;
  line-height: 1.5; }
  @media (min-width: 40em) {
    body {
      font-size: 100%; } }
  @media (min-width: 64em) {
    body {
      font-size: 112.5%; } }

h1 {
  margin: .3em 0;
  font-size: 2.66667em;
  font-weight: 400; }

h2 {
  margin: 0;
  font-size: 0.88889em;
  font-weight: 700;
  letter-spacing: .2em;
  text-transform: uppercase;
  color: #bbbbbb; }

h3 {
  margin: .6em 0;
  font-size: 2em;
  font-weight: 400;
  color: #2f3440; }

h4 {
  margin: 0;
  font-size: 1em;
  font-weight: 700; }

h5 {
  font-size: 3em;
  font-weight: 400; }

p {
  margin: 1em 0; }

a {
  text-decoration: none;
  color: #cc3333; }

.container, .small-container {
  width: 80%;
  max-width: 66.66667em;
  margin: 0 auto;
  *zoom: 1; }
  .container:before, .small-container:before {
    content: "";
    display: table; }
  .container:after, .small-container:after {
    content: "";
    display: table;
    clear: both; }

.small-container {
  max-width: 60em; }

@media (min-width: 40em) {
  [class^="col-"] {
    float: left;
    padding: 0 1em; } }

@media (min-width: 40em) {
  .col-6 {
    width: 100%; }
  .col-5 {
    width: 83.3333333333%; }
  .col-4 {
    width: 66.6666666667%; }
  .col-3 {
    width: 50%; }
  .col-2 {
    width: 33.3333333333%; }
  .col-1 {
    width: 16.6666666667%; }
  .col-offset-1 {
    margin-left: 16.6666666667%; }
  .col-offset-2 {
    margin-left: 33.3333333333%; }
  .col-offset-3 {
    margin-left: 50%; } }

@-webkit-keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    -webkit-transform: translate(-50%, 0); }

  40% {
    -webkit-transform: translate(-50%, -30px); }

  60% {
    -webkit-transform: translate(-50%, -15px); } }

@-moz-keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    -moz-transform: translate(-50%, 0); }

  40% {
    -moz-transform: translate(-50%, -30px); }

  60% {
    -moz-transform: translate(-50%, -15px); } }

@-o-keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    -o-transform: translate(-50%, 0); }

  40% {
    -o-transform: translate(-50%, -30px); }

  60% {
    -o-transform: translate(-50%, -15px); } }

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translate(-50%, 0); }

  40% {
    transform: translate(-50%, -30px); }

  60% {
    transform: translate(-50%, -15px); } }

.cssanimations .arrow-bounce {
  -webkit-animation: bounce 2s 1s;
  -moz-animation: bounce 2s 1s;
  -ms-animation: bounce 2s 1s;
  -o-animation: bounce 2s 1s;
  animation: bounce 2s 1s; }

@-webkit-keyframes fade {
  0% {
    opacity: 0; }

  100% {
    opacity: 1; } }

@-moz-keyframes fade {
  0% {
    opacity: 0; }

  100% {
    opacity: 1; } }

@-o-keyframes fade {
  0% {
    opacity: 0; }

  100% {
    opacity: 1; } }

@keyframes fade {
  0% {
    opacity: 0; }

  100% {
    opacity: 1; } }

.cssanimations .fade {
  opacity: 0;
  -webkit-animation: fade 2s forwards;
  -moz-animation: fade 2s forwards;
  -ms-animation: fade 2s forwards;
  -o-animation: fade 2s forwards;
  animation: fade 2s forwards; }

.cssanimations .delay-1 {
  -webkit-animation-delay: 0.5s;
  -moz-animation-delay: 0.5s;
  -ms-animation-delay: 0.5s;
  -o-animation-delay: 0.5s;
  animation-delay: 0.5s; }

.cssanimations .delay-2 {
  -webkit-animation-delay: 1s;
  -moz-animation-delay: 1s;
  -ms-animation-delay: 1s;
  -o-animation-delay: 1s;
  animation-delay: 1s; }

*, *:before, *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }

section {
  padding: 6em 0;
  position: relative;
  text-align: center;
  background: #fff; }

section.primary {
  text-align: left;
  background: #cc3333;
  color: #fff; }

section.secondary {
  background: #339966;
  color: #fff; }

section.light {
  background: #eee; }

section.dark {
  background: #2f3440; }

section.dark h3 {
  color: #fff; }

@media (min-width: 64em) {
  section.vh {
    height: 100vh;
    padding: 0; }
  section.vh .middle {
    width: 100%;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%); } }

.cssanimations section.scrollfade .middle {
  opacity: 0;
  -webkit-transition: opacity 1s ease-out;
  -moz-transition: opacity 1s ease-out;
  -ms-transition: opacity 1s ease-out;
  -o-transition: opacity 1s ease-out;
  transition: opacity 1s ease-out; }

.cssanimations section.scrollfade.show .middle {
  opacity: 1; }

.btn, .btn-big {
  margin: 1em 0 0 0;
  padding: .8em 1em;
  display: inline-block;
  border: 3px solid #999999;
  text-transform: uppercase;
  font-size: 0.77778em;
  font-weight: 700;
  letter-spacing: .2em;
  -webkit-transition: background 0.3s ease-out;
  -moz-transition: background 0.3s ease-out;
  -ms-transition: background 0.3s ease-out;
  -o-transition: background 0.3s ease-out;
  transition: background 0.3s ease-out; }

.btn-block {
  display: block;
  text-align: center; }

.btn-big {
  padding: 1.5em;
  font-size: 1em; }

.btn-primary {
  border: 3px solid #cc3333;
  color: #cc3333;
  margin-bottom: 20px; }
  .btn-primary:hover {
    background: #cc3333;
    color: #fff; }

.btn-secondary {
  color: #fff;
  background: rgba(51, 153, 102, 0.1);
  border: 3px solid #339966; }
  .btn-secondary:hover, .btn-secondary.active {
    background: #339966; }

.btn-group .btn, .btn-group .btn-big {
  width: 50%;
  float: left; }

.btn-group .btn:first-child, .btn-group .btn-big:first-child {
  border-right: none; }

.fa-code, .fa-upload, .fa-bar-chart, .fa-shopping-cart, .fa-link, .fa-cloud-upload, .fa-refresh {
  padding-left: 1%;
  color: #2f3440; }

@media (max-width: 480px) {
  .fa-code, .fa-upload, .fa-bar-chart, .fa-shopping-cart, .fa-link, .fa-cloud-upload, .fa-refresh {
    padding-left: 3%;
    color: #2f3440; } }

.icon-arrow-down {
  width: 40px;
  height: 40px;
  position: absolute;
  left: 50%;
  bottom: -20px;
  z-index: 1;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%); }
  @media (min-width: 64em) {
    .icon-arrow-down {
      bottom: 2em; } }

.side-nav {
  margin: 0;
  padding: 0;
  display: none;
  position: fixed;
  top: 50%;
  right: 2em;
  z-index: 1;
  opacity: 0;
  visibility: hidden;
  text-align: right;
  list-style-type: none;
  -webkit-transform: translate(0, -50%);
  -moz-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  -o-transform: translate(0, -50%);
  transform: translate(0, -50%);
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease; }
  @media (min-width: 40em) {
    .side-nav {
      display: block; } }

.side-nav.show {
  opacity: 1;
  visibility: visible; }

.menu-open .side-nav {
  -webkit-transform: translate(-20em, -50%);
  -moz-transform: translate(-20em, -50%);
  -ms-transform: translate(-20em, -50%);
  -o-transform: translate(-20em, -50%);
  transform: translate(-20em, -50%); }

.side-nav .circle {
  width: 10px;
  height: 10px;
  display: inline-block;
  border: 3px solid rgba(0, 0, 0, 0.2);
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  border-radius: 10px; }

.side-nav a:hover .circle {
  border: 3px solid #cc3333;
  background: #cc3333; }

.side-nav .label {
  padding: 0 3em 0 0;
  position: absolute;
  right: 0;
  opacity: 0;
  visibility: hidden;
  font-weight: 700;
  color: #999999;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out; }

.side-nav a:hover .label {
  padding: 0 2em 0 0;
  opacity: 1;
  visibility: visible; }

body {
  overflow-x: hidden; }

.menu {
  width: 20em;
  height: 100%;
  padding: 1em;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 0;
  background: #2f3440;
  *zoom: 1; }
  .menu:before {
    content: "";
    display: table; }
  .menu:after {
    content: "";
    display: table;
    clear: both; }
  @media (min-width: 75em) {
    .menu {
      width: 100%;
      height: auto;
      z-index: 1;
      position: fixed;
      padding: 1rem; } }

.menu.scroll {
  padding: .1em; }

.menu .icon-close {
  position: absolute;
  top: 2em;
  right: 2em;
  cursor: pointer; }
  @media (min-width: 75em) {
    .menu .icon-close {
      display: none; } }

.menu-wrapper {
  position: relative; }

nav ul {
  margin: 3 0 1em 0;
  padding: 0;
  list-style-type: none; }
  @media (min-width: 75em) {
    nav ul {
      float: left; } }

nav li {
  margin: 10 0 1em 0; }
  @media (min-width: 75em) {
    nav li {
      margin-left: 1em;
      display: inline-block; } }

nav a {
  color: #999999;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: .2em;
  -webkit-transition: color 0.3s ease-out;
  -moz-transition: color 0.3s ease-out;
  -ms-transition: color 0.3s ease-out;
  -o-transition: color 0.3s ease-out;
  transition: color 0.3s ease-out; }
  nav a:hover {
    color: #fff; }

.scroll .header--nav a {
  font-size: .9rem; }

.icon-menu {
  position: fixed;
  top: 2em;
  right: 2em;
  z-index: 1;
  cursor: pointer;
  -webkit-transition: -webkit-transform 0.5s ease;
  -moz-transition: -moz-transform 0.5s ease;
  -o-transition: -o-transform 0.5s ease;
  transition: transform 0.5s ease; }
  @media (min-width: 75em) {
    .icon-menu {
      display: none; } }

.menu-open .icon-menu {
  -webkit-transform: translateX(-20em);
  -moz-transform: translateX(-20em);
  -ms-transform: translateX(-20em);
  -o-transform: translateX(-20em);
  transform: translateX(-20em); }

@media (min-width: 75em) {
  .menu .btn, .menu .btn-big {
    margin: 0;
    float: right; } }

.content {
  background: #2f3440;
  background: #fff;
  -webkit-transition: -webkit-transform 0.5s ease;
  -moz-transition: -moz-transform 0.5s ease;
  -o-transition: -o-transform 0.5s ease;
  transition: transform 0.5s ease; }

.menu-open .content {
  -webkit-transform: translateX(-20em);
  -moz-transform: translateX(-20em);
  -ms-transform: translateX(-20em);
  -o-transform: translateX(-20em);
  transform: translateX(-20em); }
  @media (min-width: 75em) {
    .menu-open .content {
      -webkit-transform: translateX(0);
      -moz-transform: translateX(0);
      -ms-transform: translateX(0);
      -o-transform: translateX(0);
      transform: translateX(0); } }

footer {
  padding: 3em 0;
  position: relative;
  text-align: center;
  background: #fff; }

footer p {
  margin: 0 0 2em 0; }

.social-icons {
  margin: 0 0 1em 0;
  padding: 0;
  list-style-type: none; }

.social-icons li {
  margin: 0 .6em;
  display: inline-block; }

.social-icons a circle {
  fill: #cc3333; }

.primary {
  padding: 6em 0 8em 0; }

.primary p {
  margin: 0;
  font-size: 1.33333em; }

.primary p a {
  padding: .3em 0;
  color: #fff;
  border-bottom: 3px solid rgba(255, 255, 255, 0.2);
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out; }
  .primary p a:hover {
    border-bottom: 3px solid #fff; }

.illustration {
  margin: 2em 0; }
  .illustration svg {
    width: 100%; }

.openbay-logo-reduced {
  float: right;
  border: 1px solid;
  position: absolute;
  right: 10%; }

.openbay-logo {
  float: right;
  margin-right: 1%; }

@media (max-width: 1199px) {
  .openbay-logo {
    float: none;
    margin-right: 1%;
    position: absolute;
    bottom: 0;
    right: 3%; } }

@media (max-width: 1024px) {
  .openbay-download {
    float: none; } }

.main-features {
  padding: 1em; }

.features-intro {
  width: 80%;
  margin-left: 10%; }

.main-features-star-header {
  margin-top: -2em; }

.main-features h2 {
  font-size: 1.5em;
  color: #cc3333;
  margin-bottom: 2em; }
  @media (min-width: 30em) {
    .main-features h2 {
      font-size: 2em; } }

.main-features > div {
  padding: 2em 0; }
  .main-features > div:nth-of-type(odd) {
    background: #eee; }

.feature-last-para {
  color: #2f3440;
  font-weight: bold;
  margin-bottom: 40px; }

.dark > h2 {
  font-size: 2em;
  padding-bottom: 2em; }

.tabs {
  position: relative;
  text-align: center;
  background: #bbbbbb;
  *zoom: 1; }
  .tabs:before {
    content: "";
    display: table; }
  .tabs:after {
    content: "";
    display: table;
    clear: both; }

.tabs ul {
  margin: 0;
  padding: 0;
  list-style-type: none; }

.tabs li {
  width: 33.33%;
  display: block;
  float: left; }

.tabs li a {
  padding: 1em 0;
  display: block;
  border-top: 3px solid #999999;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: .2em;
  color: #fff; }

.tabs li a.selected {
  border-top: 3px solid #339966;
  background: #eee;
  color: #999999; }

.table {
  *zoom: 1; }
  .table:before {
    content: "";
    display: table; }
  .table:after {
    content: "";
    display: table;
    clear: both; }
  @media (min-width: 64em) {
    .table {
      margin: 3em 0 0 0; } }

.table .row {
  margin: 0 0 2em 0;
  position: relative; }
  @media (min-width: 64em) {
    .table .row {
      width: 16.6666666667%;
      margin: 0;
      float: left; } }

.table .row-etsy {
  margin: 0 0 2em 0;
  position: relative; }
  @media (min-width: 64em) {
    .table .row-etsy {
      width: 20%;
      margin: 0;
      float: left; } }

.table .row div {
  padding: 1em;
  display: none;
  border-bottom: 1px solid #eee;
  background: #fff; }
  @media (min-width: 64em) {
    .table .row div {
      border-right: 1px solid #eee;
      display: block; } }

.table .row div.head {
  height: 6em;
  padding: 0 2em;
  display: block;
  position: relative;
  line-height: 6em;
  border-bottom: none;
  cursor: pointer;
  background: #339966;
  color: #fff;
  *zoom: 1; }
  .table .row div.head:before {
    content: "";
    display: table; }
  .table .row div.head:after {
    content: "";
    display: table;
    clear: both; }
  .table .row div.head:before {
    width: 0;
    height: 0;
    content: "";
    position: absolute;
    left: 50%;
    top: 100%;
    margin-left: -6px;
    border-top: 6px solid #339966;
    border-right: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-left: 6px solid transparent; }
  @media (min-width: 64em) {
    .table .row div.head {
      height: auto;
      padding: 1em 0;
      line-height: 1.5;
      border-right: 1px solid #2d8659;
      cursor: default; } }

.table .row.show div {
  display: block; }

.table .popular {
  width: 100%;
  height: 3em;
  position: absolute;
  display: none;
  bottom: 100%;
  left: 0;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: .2em;
  line-height: 3em;
  background: #ddd;
  color: #339966; }
  @media (min-width: 64em) {
    .table .popular {
      display: block; } }

.table h2 {
  float: left;
  font-size: 1em;
  color: #fff; }
  @media (min-width: 64em) {
    .table h2 {
      font-size: 1em;
      float: none; } }

.table h3 {
  float: right;
  margin: 0;
  font-size: 2em;
  color: #fff; }
  @media (min-width: 40em) {
    .table h3 {
      font-size: 3em; } }
  @media (min-width: 64em) {
    .table h3 {
      float: none; } }

.table h3 sup, .table h3 span {
  font-size: .5em; }

.table p {
  margin: 0; }

.table strong {
  padding: 0 .3em;
  margin: 0 0 1em 0;
  font-weight: 700;
  color: #339966;
  background: #d9f2e6; }

.prepay {
  width: 100%;
  float: left; }
  @media (min-width: 64em) {
    .prepay {
      margin: 6em 0 0 0; } }

.prepay .row {
  width: 80%;
  margin-left: 10%; }

.prepay .row div.head {
  background: #2f3440;
  border-right: 1px solid #242831; }
  .prepay .row div.head:before {
    border-top: 6px solid #2f3440; }

#plan-etsy, #plan-amazon, #plan-ebay {
  display: inline-block;
  margin-top: 10px; }

.btn-primary {
  margin-top: 20px; }

@media (min-width: 40em) {
  .hide-1 {
    display: none !important; } }

@media (min-width: 64em) {
  .hide-2 {
    display: none !important; } }

.show {
  display: block !important; }

.cf:before, .cf:after {
  content: " ";
  /* 1 */
  display: table;
  /* 2 */ }

.cf:after {
  clear: both; }

/**
 * For IE 6/7 only
 * Include this rule to trigger hasLayout and contain floats.
 */
.cf {
  *zoom: 1; }
